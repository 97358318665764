import { formatTime } from '@/app/utils/dates';
import { useAppSelector } from '@/hooks/useAppSelector';
import { IChannelPost, ITempSendMessage } from '@/pages/chats/types';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React, { memo, useCallback } from 'react';
import { Virtuoso } from 'react-virtuoso';
import TempUserMessage from '../TempUserMessage';
import UserMessage from '../UserMessage';
import selector from './selector';

interface ISystemMessageProps {
  post: IChannelPost;
}

const SystemMessage = (props: ISystemMessageProps) => {
  const { post } = props;

  return (
    <Grid container justifyContent="center">
      <Typography variant="body2">
        {post.message} {formatTime(post.update_at, { dateFormat: 'd MMM yyyy' })}
      </Typography>
    </Grid>
  );
};

interface ITempMessageProps {
  post: ITempSendMessage;
}

const TempMessage = (props: ITempMessageProps) => {
  const { post } = props;

  return (
    <Grid container justifyContent="center">
      <Typography variant="body2">
        {post.message} {/* {formatTime(post.update_at, { dateFormat: 'd MMM yyyy' })} */}
      </Typography>
    </Grid>
  );
};

interface IPostMessageProps {
  post: IChannelPost;
}

const PostMessage = memo((props: IPostMessageProps) => {
  const { post } = props;

  if (Object.keys(post.props).length) {
    return <SystemMessage post={post} />;
  }

  return <UserMessage post={post} />;
});

const Channel = () => {
  const { posts } = useAppSelector(selector);

  const renderTree = useCallback(
    (idx: number) => {
      const item = posts[idx];
      if (!item) {
        return null;
      }
      if ((item as ITempSendMessage)?.tempSendMessage) {
        return <TempUserMessage key={item.id} post={item as ITempSendMessage} />;
      }

      return <PostMessage key={item.id} post={item as IChannelPost} />;
    },
    [posts]
  );

  return (
    <Grid item xs rowGap={3} minWidth="100%">
      <Virtuoso
        style={{ overflow: 'hidden auto' }}
        totalCount={posts?.length || 0}
        overscan={10}
        itemContent={renderTree}
        initialTopMostItemIndex={Math.max(posts?.length - 1, 0)}
        followOutput
        // followOutput={(isAtBottom: boolean) => {
        //   if (isAtBottom) {
        //     return 'smooth';
        //   } else {
        //     return false;
        //   }
        // }}
      />
    </Grid>
  );
};

export default Channel;
