import { useAppSelector } from '@/hooks/useAppSelector';
import ChatImageCarouselModalData from '@/pages/chats/Modals/command/ChatImageCarouselModalData';
import React from 'react';
import { useDispatch } from 'react-redux';
import { closeChatModal } from './actions';
import ChatImageCarousel from './ChatsImageCarousel';
import selector from './selector';

const ChatsModals = () => {
  const dispatch = useDispatch();
  const handleClose = () => dispatch(closeChatModal());

  const state = useAppSelector(selector);

  return (
    <>
      <ChatImageCarousel
        opened={state.opened && state.data instanceof ChatImageCarouselModalData}
        onClose={handleClose}
      />
    </>
  );
};

export default ChatsModals;
