import Button from '@core-ui/button';
import SendIcon from '@mui/icons-material/Send';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import SvgIcon from '@mui/material/SvgIcon';
import { useAppSelector } from '@/hooks/useAppSelector';
import { IMention } from '@/pages/chats/types';
import { UploadSimple } from '@phosphor-icons/react';
import React, { ChangeEvent, KeyboardEvent, useCallback, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Mention, MentionsInput } from 'react-mentions';
import { useDispatch } from 'react-redux';
import { addUser, notifyUser, sendChatMessage } from '../../actions';
import ChannelInputFiles from '../ChannelInputFiles';
import { mentionStyles } from './styles';

interface IProps {
  chatId: string;
}

const ChannelInput = (props: IProps) => {
  const { chatId } = props;
  const [value, setValue] = useState('');
  const [files, setFiles] = useState<File[]>([]);
  const [mentions, setMentions] = useState<IMention[]>([]);

  const users = useAppSelector((state) => state.dictionary.employees);
  const displayUsers = users.map((user) => ({ id: user.id, display: `${user.first_name} ${user.last_name}` }));

  const inputRef = useRef() as React.MutableRefObject<HTMLTextAreaElement>;

  const dispatch = useDispatch();

  const onInputChange = (e: ChangeEvent<HTMLTextAreaElement>, _v: string, _v2: string, mentions: IMention[]) => {
    setValue(e.target.value);
    setMentions(mentions);
  };

  const onDrop = useCallback((acceptedFiles) => {
    setFiles((f: File[]) => [...f, ...acceptedFiles]);
  }, []);

  const removeFile = (fileToDelete: File) => setFiles((state) => state.filter((f) => f.name !== fileToDelete.name));

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const onMessageSubmit = () => {
    if (value.length || files.length) {
      dispatch(sendChatMessage({ files, chatId, message: value }));
      dispatch(addUser({ mentions, chatId }));
      dispatch(notifyUser({ mentions, chatId }));
      setValue('');
      setFiles([]);
      inputRef?.current?.focus();
    }
  };

  const onKeyDown = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      e.stopPropagation();
      onMessageSubmit();
    }
  };

  return (
    <>
      <ChannelInputFiles files={files} removeFile={removeFile} />
      <Grid
        container
        alignItems="center"
        wrap="nowrap"
        sx={{
          width: '100%',
          border: '1px solid #B6BCC2',
          padding: '6px',
          borderRadius: '4px',
        }}
      >
        <Grid item alignSelf="end" {...getRootProps()} sx={{ cursor: 'pointer' }}>
          <input {...getInputProps()} />
          <SvgIcon>
            <UploadSimple size={16} />
          </SvgIcon>
        </Grid>
        <MentionsInput
          value={value}
          // @ts-ignore
          onChange={onInputChange}
          forceSuggestionsAboveCursor
          style={mentionStyles}
          placeholder="What would you like to write?"
          // @ts-ignore
          onKeyDown={onKeyDown}
        >
          <Mention trigger="@" data={displayUsers} markup="@[__display__]" />
        </MentionsInput>
        <Grid component={IconButton} alignSelf="end" disableFocusRipple disableRipple {...getRootProps()}>
          {/* <Box>
            <input {...getInputProps()} />
            <PaperClipIconOcean fill="#000" />
          </Box> */}
        </Grid>

        <Grid item alignSelf="end">
          <Button variant="contained" color="blue" compact onClick={onMessageSubmit}>
            <SendIcon />
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default ChannelInput;
