import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { IRouteParams } from '@/app/consts/routes';
import React from 'react';
import { useParams } from 'react-router-dom';
import Channel from './components/Channel';
import ChannelHeader from './components/ChannelHeader';
import ChannelInput from './components/ChannelInput';
import NoChannelSelected from './components/NoChannelSelected';

const ChatsChannel = () => {
  const { chatId } = useParams<IRouteParams>();

  return (
    <Grid
      container
      direction="column"
      component={Paper}
      sx={{ padding: '21px 24px' }}
      wrap="nowrap"
      justifyContent="center"
      alignItems="center"
      height="100%"
    >
      {!chatId ? (
        <NoChannelSelected />
      ) : (
        <>
          <ChannelHeader chatId={chatId} />
          <Channel />
          <ChannelInput chatId={chatId} />
        </>
      )}
    </Grid>
  );
};

export default ChatsChannel;
