import { buildURL } from '@core-ui/url';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { historyPush } from '@/app/actions';
import { IRouteParams, ROUTES } from '@/app/consts/routes';
import { useAppSelector } from '@/hooks/useAppSelector';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Virtuoso } from 'react-virtuoso';

import ChatCard from './components/ChatCard';
import ChatsListSkeleton from './components/ChatsListSkeleton';
import selector from './selector';

const ChatsList = () => {
  const { items, loaded, loading, hasData } = useAppSelector(selector);
  const { boatId, chatId } = useParams<IRouteParams>();

  const dispatch = useDispatch();
  const selectChat = (id: any) => dispatch(historyPush(buildURL(`/${boatId}/${ROUTES.CHATS}/${id}`)));

  const renderTree = useCallback(
    (idx: number) => {
      const item = items[idx];

      return (
        <ChatCard
          key={item.id}
          item={item}
          selectChat={selectChat}
          cardColor={chatId === item.id ? 'rgba(255, 255, 255, 0.5)' : '#fff'}
        />
      );
    },
    [items]
  );

  return (
    <Grid
      item
      container
      xs={12}
      md={3}
      overflow="auto"
      direction="column"
      rowGap={2}
      wrap="nowrap"
      sx={{ flexGrow: 1, minHeight: 0 }}
    >
      {loading && !hasData ? (
        <ChatsListSkeleton />
      ) : (
        <>
          {' '}
          <Typography variant="h3">Your chats</Typography>
          <Virtuoso style={{ height: '100%' }} totalCount={items?.length || 0} overscan={10} itemContent={renderTree} />
        </>
      )}
    </Grid>
  );
};

export default ChatsList;
